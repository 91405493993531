<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="height"
    :height="width"
    viewBox="0 0 18.828 13.414"
  >
    <defs></defs>
    <g transform="translate(1.414 1.414)">
      <path class="a" d="M20,6,9,17,4,12" transform="translate(-4 -6)" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '11',
    },
    width: {
      type: String,
      default: '16',
    },
    color: {
      type: String,
      default: 'text-color-darker',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.a {
  fill: none;
  stroke: var(--grey-strong);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}
</style>
